import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  practiceIQBasic: [],
  isLoadingPracticeIQBasic: false,
  isErrorPracticeIQBasic: null,
  statusPracticeIQBasic: '',
  ////////////////
  practiceIQTxPatientOverview: [],
  isLoadingPatientOverview: false,
  isErrorPatientOverview: null,
  statusPatientOverview: '',
  ////////////////
  practiceIQTxPatientServices: [],
  isLoadingPatientServices: false,
  isErrorPatientServices: null,
  statusPatientServices: '',
  ///////////////////////////////
  practiceIQTxPatientOverviewCollection: [],
  isLoadingPatientOverviewCollection: false,
  isErrorPatientOverviewCollection: null,
  statusPatientOverviewCollection: '',
  /////////////
  practiceIQTxTratementPlan: [],
  isLoadingTratementPlan: false,
  isErrorTratementPlan: null,
  statusTratementPlan: '',
};

export const fetchPracticeIQBasic = createAsyncThunk(
  'practiceIQBasic/practiceIQBasicList',
  async (params, { rejectWithValue }) => {
    // console.log('params', params)
    try {
      const { data } = await axiosInstance.get(
        `/api/Practice/get-dashboard?ClinicId=${params.inputFields}&startdate=${params.startDate}&enddate=${params.endDate}`
      );
      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

export const fetchPracticePatientOverview = createAsyncThunk(
  'practiceIQPatientOverview/practiceIQTxPatientOverview',
  async (params, { rejectWithValue }) => {
    // console.log('params', params)
    try {
      const { data } = await axiosInstance.get(
        // https://api.dentalbookingonline.com/api/Practice/get-patientview?Id=309621&startdate=2023-06-23&enddate=2023-06-23
        `/api/Patient/get-patientview?Id=${params.patientOverViewData}&startdate=${params.startDate}&enddate=${params.endDate}`
      );
      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

export const fetchPracticePatientServices = createAsyncThunk(
  'practiceIQPatientServices/practiceIQTxPatientServices',
  async (params, { rejectWithValue }) => {
    // console.log('params', params)
    try {
      const { data } = await axiosInstance.get(
        // https://api.dentalbookingonline.com/api/Practice/get-patientview?Id=309621&startdate=2023-06-23&enddate=2023-06-23
        `/api/Patient/get-patientservices/${params.patientOverViewData}`
      );
      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

export const fetchPracticePatientOverviewCollection = createAsyncThunk(
  'practiceIQPatientOverviewCollection/practiceIQTxPatientOverviewCollection',
  async (params, { rejectWithValue }) => {
    // console.log('params', params)
    try {
      const { data } = await axiosInstance.get(
        // https://api.dentalbookingonline.com/api/Practice/get-patientcollection?Id=309621&startdate=2023-06-23&enddate=2023-06-23
        `/api/Patient/get-patientcollection?Id=${params.patientOverViewData}&startdate=${params.startDate}&enddate=${params.endDate}`
      );
      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

//https://api.dentalbookingonline.com/api/Patient/get-patientplans/{Id}

export const fetchTreatmentPlan = createAsyncThunk(
  'practiceIQTTratementPlan/practiceIQTxTratementPlan',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        // https://api.dentalbookingonline.com/api/Practice/get-patientcollection?Id=309621&startdate=2023-06-23&enddate=2023-06-23
        `/api/Patient/get-patientplans/${params.patientOverViewData}`
      );
      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

const productionSlice = createSlice({
  name: 'practiceIQList',
  initialState,
  extraReducers: {
    [fetchPracticeIQBasic.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.practiceIQBasic = payload.data;
      } else {
        state.practiceIQBasic = [];
      }
      // state.practiceIQBasic = payload;
      state.isLoadingPracticeIQBasic = false;
      state.statusPracticeIQBasic = 'Success';
      state.isErrorPracticeIQBasic = null;
    },
    [fetchPracticeIQBasic.pending]: (state, { payload }) => {
      state.practiceIQBasic = [];
      state.isLoadingPracticeIQBasic = true;
      state.statusPracticeIQBasic = '';
      state.isErrorPracticeIQBasic = null;
    },
    [fetchPracticeIQBasic.rejected]: (state, { payload }) => {
      state.practiceIQBasic = [];
      state.isLoadingPracticeIQBasic = false;
      state.isErrorPracticeIQBasic = payload;
      state.statusPracticeIQBasic = 'bad req';
    },
    [fetchPracticePatientOverview.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.practiceIQTxPatientOverview = payload.data;
      } else {
        state.practiceIQTxPatientOverview = [];
      }
      // state.practiceIQTxPatientOverview = payload;
      state.isLoadingPatientOverview = false;
      state.statusPatientOverview = 'Success';
      state.isErrorPatientOverview = null;
    },
    [fetchPracticePatientOverview.pending]: (state, { payload }) => {
      state.practiceIQTxPatientOverview = [];
      state.isLoadingPatientOverview = true;
      state.statusPatientOverview = '';
      state.isErrorPatientOverview = null;
    },
    [fetchPracticePatientOverview.rejected]: (state, { payload }) => {
      state.practiceIQTxPatientOverview = [];
      state.isLoadingPatientOverview = false;
      state.isErrorPatientOverview = payload;
      state.statusPatientOverview = 'bad req';
    },
    [fetchPracticePatientServices.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.practiceIQTxPatientServices = payload.data;
      } else {
        state.practiceIQTxPatientServices = [];
      }
      // state.practiceIQTxPatientServices = payload;
      state.isLoadingPatientServices = false;
      state.statusPatientServices = 'Success';
      state.isErrorPatientServices = null;
    },
    [fetchPracticePatientServices.pending]: (state, { payload }) => {
      state.practiceIQTxPatientServices = [];
      state.isLoadingPatientServices = true;
      state.statusPatientServices = '';
      state.isErrorPatientServices = null;
    },
    [fetchPracticePatientServices.rejected]: (state, { payload }) => {
      state.practiceIQTxPatientServices = [];
      state.isLoadingPatientServices = false;
      state.isErrorPatientServices = payload;
      state.statusPatientServices = 'bad req';
    },
    [fetchPracticePatientOverviewCollection.fulfilled]: (
      state,
      { payload }
    ) => {
      if (payload.status.isSuccess === true) {
        state.practiceIQTxPatientOverviewCollection = payload.data;
      } else {
        state.practiceIQTxPatientOverviewCollection = [];
      }
      // state.practiceIQTxPatientOverviewCollection = payload;
      state.isLoadingPatientOverviewCollection = false;
      state.statusPatientOverviewCollection = 'Success';
      state.isErrorPatientOverviewCommunication = null;
    },
    [fetchPracticePatientOverviewCollection.pending]: (state, { payload }) => {
      state.practiceIQTxPatientOverviewCollection = [];
      state.isLoadingPatientOverviewCollection = true;
      state.statusPatientOverviewCollection = '';
      state.isErrorPatientOverviewCommunication = null;
    },
    [fetchPracticePatientOverviewCollection.rejected]: (state, { payload }) => {
      state.practiceIQTxPatientOverviewCollection = [];
      state.isLoadingPatientOverviewCollection = false;
      state.isErrorPatientOverviewCommunication = payload;
      state.statusPatientOverviewCollection = 'bad req';
    },
    /////////////////
    [fetchTreatmentPlan.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        // console.log('payload :>> ', payload);
        state.practiceIQTxTratementPlan = payload.data;
      } else {
        state.practiceIQTxTratementPlan = [];
      }
      // state.practiceIQBasic = payload;
      state.isLoadingTratementPlan = false;
      state.statusTratementPlan = 'Success';
      state.isErrorTratementPlan = null;
    },
    [fetchTreatmentPlan.pending]: (state, { payload }) => {
      state.practiceIQTxTratementPlan = [];
      state.isLoadingTratementPlan = true;
      state.statusTratementPlan = '';
      state.isErrorTratementPlan = null;
    },
    [fetchTreatmentPlan.rejected]: (state, { payload }) => {
      state.practiceIQTxTratementPlan = [];
      state.isLoadingTratementPlan = false;
      state.isErrorTratementPlan = payload;
      state.statusTratementPlan = 'bad req';
    },
  },
});

export default productionSlice.reducer;
