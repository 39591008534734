import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  bookingReportData: [],
  isLoadingbookingReportData: false,
  isError: null,
  status: '',
};

export const fetchBookingReport = createAsyncThunk(
  'bookingReport/bookingeportList',
  async (params, { rejectWithValue }) => {
    // console.log('params-------- :>> ', params);
    try {
      const { data } = await axiosInstance.get(
        //https://api.dentalbookingonline.com/api/Dashboard/bookingreports/0/2024-09-02/2024-09-27
        `api/Dashboard/bookingreports/${params.id}/${params.userId}/${params.startDate}/${params.endDate}`
      );

      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

const bookingReportSlice = createSlice({
  name: 'bookingeportList',
  initialState,
  extraReducers: {
    [fetchBookingReport.fulfilled]: (state, { payload }) => {
      // console.log('payload :>> ', payload);
      if (payload.status.isSuccess === true) {
        state.bookingReportData = payload.data;
      } else {
        state.bookingReportData = [];
      }
      state.isLoadingbookingReportData = false;
      state.status = 'Success';
      state.isError = null;
    },
    [fetchBookingReport.pending]: (state, { payload }) => {
      state.isLoadingbookingReportData = true;
      state.status = '';
      state.isError = null;
      state.bookingReportData = [];
    },
    [fetchBookingReport.rejected]: (state, { payload }) => {
      state.isLoadingbookingReportData = false;
      state.isError = payload;
      state.status = 'Rejected';
      state.bookingReportData = [];
    },
  },
});

export default bookingReportSlice.reducer;
